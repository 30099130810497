/** @jsx jsx */
import { Styled, jsx, Flex, Box, Grid } from "theme-ui"

import { graphql } from 'gatsby'

import styled from '@emotion/styled'

import Layout from "../components/layout"

import SEO from "../components/seo"

import BasicHero from '../components/BasicHero'
import ShapedCard from '../components/ShapedCard'
import BgImage from '../components/BgImage'
import Button from '../components/button'
import Card from '../components/Card'
import AdvancedSlider from '../components/AdvancedSlider'


import { Container } from '../components/item'


import BlackBg from '../images/black-box-bg.jpg'


export const query = graphql`
  query {
    hero: file(relativePath: { eq: "Raptect-product-parent-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lamboImage: file(relativePath: { eq: "lambo-arial.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tintRoll: file(relativePath: { eq: "tint-roll.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blueBackground: file(relativePath: { eq: "blue-background.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    products: allShopifyProduct(filter: {vendor: {eq: "Raptect"}}, sort: {fields: handle}) {
      edges {
        node {
          id
          title
          handle
          images {
            localFile {
              childImageSharp {
                fluid(maxWidth: 520) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    productsMetadata: shopifyunstableapi {
      products(first: 10, query: "Raptect") {
        edges {
          node {
            handle
            metafield(key: "blurb", namespace: "product") {
              value
            }
          }
        }
      }
    }
    applications: allApplicationsJson {
      edges {
        node {
          title
          path
          excerpt
          excerpt_image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`


const ContentBox = styled(Box)`
    position: relative;
    z-index: 10;
    padding: 40px 30px;
    padding-left: 20px;

    ul {
      list-style: disc;

      li {
        font-size: 18px;
        line-height: 1.2;
        margin-bottom: 10px;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 20px 15px;
    }
  `

const ItemDetails = styled(ContentBox)`
  box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.25);    
  background-image: url(${BlackBg});
  background-size: cover;
`


const ProductCategoryPage = ({data}) => {

  const metadataArr = data.productsMetadata.products.edges;

  return (
    <Layout>
      <SEO title="Raptect Products" />

      <BasicHero 
        fluid={data.hero.childImageSharp.fluid}  
        title={"Jeep in Desert"} 
        heroTitle={"Raptect Window Tint"}
        shapeColor={'transparent'}
      />

      <Box as="section">
        <Container>
          <Box as="section" bg="white" my={13}>
            <ShapedCard
              sx={{color: 'white'}}
              fluidImg={data.tintRoll.childImageSharp.fluid} 
              title='Nano-Ceramic Window Tint'
              text="The highest-quality, longest-lasting window protection on the market. Raptect nano-ceramic window tint technology filters 99% of UV and and 80% of Infrared rays without blocking visible light. Long-lasting and cost-effective, it protects privacy, interiors, and the windows themselves while conserving energy and providing powerful temperature control."/>
          </Box>
        </Container>
      </Box>
    
      <Box as="section" bg='purple'>
        <Container py={13} color='white' sx={{textAlign: ['center', 'left']}}>
          <Styled.h2 sx={{marginBottom: '60px', marginLeft: ['auto','60px','30px'], marginRight: ['auto', 'none']}}>All Products</Styled.h2>
          <Grid columns={[1, 2, 3]} gap={[8,8,14]} sx={{justifyItems: 'center'}}>
            {data.products.edges.map(product => {

              const productHandle = product.node.handle;
              const metadata = (metadataArr.find(x => x.node.handle === productHandle).node.metafield);

              const blurbText = metadata ? metadata.value : 'Protection and privacy without glare, headlight distortion, or reduced visibility.';

              return (
                <Card
                  key={product.node.id}
                  color='white'
                  title={product.node.title}
                  titleColor='white'
                  fluidImg={product.node.images[0].localFile.childImageSharp.fluid}
                  roundEdges
                  shadow
                  text={blurbText}
                >
                  <Button variant={'whiteOutline'} to={`/products/${product.node.handle}`} text="See Product" />
                </Card>
              ) 
            })}
          </Grid>
          <Flex sx={{justifyContent: 'space-between', flexDirection: ['column', 'row'], alignItems: ['center', 'stretch']}}>
            
          </Flex>
        </Container>
      </Box>

      <BgImage
        fluid={data.lamboImage.childImageSharp.fluid}
        title="stock car image"
        >
        <Container py={13}>
          <Flex py={13} sx={{justifyContent: ['flex-start', 'flex-end'] }} w={'100%'}>
            <Box color={'white'} sx={{maxWidth: ['unset', '50%']}}>
              <Styled.h2>Lifetime Performance</Styled.h2>
              <Styled.p>Raptect premium-grade nano ceramic window tints last up to 4X longer than other types of films, providing maintenance free-performance for decades.</Styled.p>
            </Box>
          </Flex>
        </Container>
      </BgImage>

      <Box as="section" color={'white'} backgroundColor={'purple'}>
        <Container py={13}>
          <Flex sx={{ flexDirection: ['column', 'row'], alignItems: 'center', width: '100%', margin: '0 auto'}}>
            <Flex color='white' sx={{flex: '1 1 50%', flexDirection: 'column', paddingRight: '40px'}}>
              <Box sx={{width: ['100%', '100%', '500px']}}>
                <Styled.h2>Window Tint Technology</Styled.h2>
                <Styled.p sx={{variant: 'text.body'}}>Raptect delivers smart results regardless of window type or protection needs. Its responsive technology allows it to conform to curved surfaces and shield interiors no matter the season. Filtering - not blocking - natural light, it reflects heat inside in the winter and outside in the summer.</Styled.p>
              </Box>
            </Flex>
            <Box sx={{flex: '1 1 460px', position: 'relative'}}>
                <ItemDetails sx={{marginTop: ['40px', 0]}} color='white'>
                  <ul>
                    <li>No glare, headlight distortion or “halo effect”</li>
                    <li>No cell signal interference</li>
                    <li>Filters 99% of UV (cancer-causing) rays</li>
                    <li>Filters 95% of infrared (interior/furniture-fading) rays</li>
                    <li>Powerful temperature control</li>
                    <li>Protects and insulates windows</li>
                  </ul>
                </ItemDetails>
            </Box>
          </Flex>
        </Container>
      </Box>

      <AdvancedSlider data={data.applications.edges} />
      
      <BgImage
        fluid={data.blueBackground.childImageSharp.fluid}
        title="abstract background"
      >
        <Container py={13} sx={{textAlign: 'center', color: 'white', backgroundColor: ['blue', 'transparent']}}>
          <Styled.h2 sx={{marginY: '40px'}}>The Raptect Advantage</Styled.h2>
          <Flex sx={{justifyContent: 'space-between', flexDirection: ['column', 'row'], alignItems: ['center','flex-start'] }}>
            <Card
              color='white'
              title="Cool, Comfortable Protection"
              titleColor='white'
              text="Cooling comfort and protection from the sun’s harmful rays"
            >
            </Card>
            <Card
              color='white'
              title="Safe, Secure, Dependable"
              titleColor='white'
              text="You can depend on Raptect window tints to protect your safety and privacy"
            >
            </Card>
            <Card
              color='white'
              title="Accurate, Detailed, Refined"
              titleColor='white'
              text="Our tints apply accurately and imperceptibly for a sophisticated look"
            >
            </Card>
          </Flex>
        </Container>
      </BgImage>
      
    
    </Layout>
  )
  
}

export default ProductCategoryPage
